import {
  email,
  facebookSquare,
  pintrestSquare,
  twitterSquare,
} from '@emobg/vue-base';

export const SOCIAL_PLATFORMS = {
  facebook: 'facebook',
  twitter: 'twitter',
  pinterest: 'pinterest',
  mail: 'mail',
};

export const FACEBOOK_URL = url => `http://www.facebook.com/sharer/sharer.php?u=${url}`;
export const TWITTER_URL = text => `https://twitter.com/intent/tweet?text=${text}`;
export const PINTEREST_URL = (url, text) => `https://pinterest.com/pin/create/bookmarklet/?media=https://rest.bluemove.es/assets/img/webapp/2014/social/cover.png&url=${url}&is_video=false&description=${text}`;

export const SOCIAL_NETWORKS = [
  { name: SOCIAL_PLATFORMS.facebook, icon: facebookSquare },
  { name: SOCIAL_PLATFORMS.twitter, icon: twitterSquare },
  { name: SOCIAL_PLATFORMS.pinterest, icon: pintrestSquare },
  { name: SOCIAL_PLATFORMS.mail, icon: email },
];
