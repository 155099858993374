export default Object.freeze({
  BOOKING_TYPE: {
    CAR_SHARING: 'carsharing',
    LONG_DISTANCE: 'longdistance',
    MOTO_SHARING: 'motosharing',
  },
  COMPONENT_VIEW: {
    MAP: 'map',
    VEHICLE_DETAIL: 'vehicle_detail',
  },
});
