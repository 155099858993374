export default Object.freeze({
  BOOKING_ID: 'booking_id',
  BOOKING_TYPE: 'booking_type',
  VEHICLE_ID: 'vehicle_id',
  CAR_MODEL: 'car_model',
  CAR_CATEGORY: 'car_category',
  AVAILABILITY: 'availability',
  VALUE: 'value',
  CURRENCY: 'currency',
  DISTANCE_FROM_THE_USER: 'distance_from_the_user',
  DISTANCE_FROM_THE_USER_ADDRESS: 'distance_from_the_user_address',
  INSURANCE_TYPE: 'insurance_type',
  SUCCESSFUL_BOOKING_DONE: 'successful_booking_done',
  UNSUCCESSFUL_BOOKING_DONE_REASON: 'unsuccessful_booking_done_reason',
  NUM_HOURS: 'num_hours',
  COMPONENT_VIEW: 'component_view',
  SUCCESSFUL_RESERVATION_SUMMARY: 'successful_reservation_summary',
  UNSUCCESSFUL_RESERVATION_SUMMARY_REASON: 'unsuccessful_reservation_summary_reason',
  PAYMENT_METHOD_AVAILABLE: 'payment_method_available',
  TYPE: 'type',
  TIME_LEFT_TO_CANCEL: 'time_left_to_cancel',
  SUCCESSFUL_CANCEL_BOOKING: 'successful_cancel_booking',
  SUCCESSFUL_CANCEL_PREBOOKING: 'successful_cancel_prebooking',
  UNSUCCESSFUL_CANCEL_BOOKING_REASON: 'unsuccessful_cancel_booking_reason',
  UNSUCCESSFUL_CANCEL_PREBOOKING_REASON: 'unsuccessful_cancel_prebooking_reason',
});
