<script>
import NotificationListManager from '@/components/NotificationList/NotificationListManager';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import { watch } from 'vue';
export default {
  name: 'MainLayoutComponent',
  components: {
    NotificationListManager,
  },
  setup() {
    const { currentActiveNotification } = useNotifications();
    function setContentHeight(isNotificationVisible = false) {
      const defaultHeight = 0;
      const expandedHeight = 60;

      const contentElement = document.getElementById('main-layout-content');
      if (!isNil(contentElement)) {
        contentElement.style.paddingTop = isNotificationVisible ? `${expandedHeight}px` : `${defaultHeight}px`;
      }
    }
    watch(
      () => currentActiveNotification.value,
      (notification) => {
        setContentHeight(!!get(notification, 'text'));
      },
      { immediate: true },
    );
    return {
      setContentHeight,
      currentActiveNotification,
    };
  },
  mounted() {
    this.setContentHeight(!!get(this, 'currentActiveNotification.text'));
  },
};
</script>

<template>
  <section class="MainLayoutComponent">
    <slot name="header" />
    <main
      class="d-flex flex-column vh-100 w-100 position-relative pt-8 overflow-hidden emobg-background-color-white"
      data-test-id="main-layout-content"
    >
      <NotificationListManager />
      <div
        id="main-layout-content"
        class="overflow-auto"
      >
        <slot />
      </div>
    </main>
  </section>
</template>
