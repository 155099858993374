export default Object.freeze({
  ACTIVATION_PROCESS_STARTED: 'Activation Process Started',
  ADDRESS_ADDED: 'Address Added',
  ADD_PAYMENT_METHOD_CLICKED: 'Add Payment Method Clicked',
  ADD_PAYMENT_METHOD_CONFIRMED: 'Add Payment Method Confirmed',
  ADD_TO_CALENDAR_CLICK: 'Add To Calendar Clicked',
  ANNUAL_INSURANCE: 'Annual Insurance',
  ANNUAL_INSURANCE_PAID: 'Annual Insurance Paid',
  ANNUAL_INSURANCE_SELECTED: 'Annual Insurance Selected',
  ATTRIBUTED_USER_SIGNED_UP: 'Attributed User Signed Up',
  B2B_COMPANY_CREATED: 'B2B Company Created',
  B2B_EMPLOYEE_CREATED: 'B2B Employee Created',
  B2B_INVITE_EMPLOYEE: 'B2B Invite Employee',
  B2B_OPT_IN_ENABLED: 'B2B Opt In Enabled',
  B2B_SIGNUP_AFTER_INVITATION: 'B2B Signup After Invitation',
  B2B_STEP_1: 'B2B Step 1',
  B2B_STEP_2: 'B2B Step 2',
  B2B_STEP_3: 'B2B Step 3',
  BADGE_ADDED: 'Badge Added',
  BADGE_CHOOSE_WAY: 'Badge - Choose Way',
  BADGE_DELIVER: 'Badge - Deliver Badge',
  BADGE_HOW_IT_WORKS: 'Badge - How It Works',
  BADGE_ORDER_CARD: 'Badge - Order Card',
  BADGE_PERSONAL_CARD: 'Badge - Personal Card',
  BADGE_PERSONAL_SUCCESS: 'Badge - Personal Card Success',
  BADGE_PICK_UP_CARD: 'Badge - Pick Up Card',
  BADGE_PICK_UP_SUCCESS: 'Badge - Pick Up Card Success',
  BADGE_SELECTED: 'Badge Selected',
  BADGE_SUCCESS: 'Badge - Order Card Success',
  BIRTHDATE_ADDED: 'Birthdate Added',
  BOOKING_SUMMARY: 'Booking Summary',
  BOOKING_TYPE_SELECTED: 'Booking Type Selected',
  CANCEL_BOOKING_CLICKED: 'Cancel Booking Clicked',
  CANCEL_PREBOOKING_CLICKED: 'Cancel PreBooking Clicked',
  CARSHARING_BOOKING_CREATED: 'Carsharing Booking Created',
  CHOOSE_NEW_LOCATION_SEARCH: 'Choose New Location Search',
  CLOSE_PREFERRED_LOCATIONS: 'Close preferred locations',
  CLOSE_RESERVATION_SUMMARY: 'Close Reservation Summary',
  COUNTRY_SELECTED: 'Ubeeqo Country Selected',
  CREATE_ACCOUNT_CLICK: 'Create Account Clicked',
  DRIVING_LICENSE_CHOOSE_COUNTRY: 'Driving License - Choose Country',
  DRIVING_LICENSE_FALLBACK_FILLED: 'Driving License - Fallback Filled',
  DRIVING_LICENSE_FALLBACK_UPLOAD: 'Driving License - Fallback Upload',
  DRIVING_LICENSE_NEXT_STEPS: 'Driving License - Next Steps',
  DRIVING_LICENSE_PENDING_VALIDATION: 'Driving License - Pending Validation',
  FILTER_CAR_DETAIL: 'Filter Car Detail',
  FILTER_CAR_SELECTED: 'Filter Car Selected',
  FILTER_DATE_OPENED: 'Filter Date Opened',
  FILTER_DATE_SELECTED: 'Filter Date Selected',
  INSURANCE_INFO: 'Insurance Info',
  INSURANCE_SELECTION: 'Insurance Selection',
  LOCATION_DETAILS_VIEWED: 'Location Details Viewed',
  LOCATION_SELECTED: 'Location Selected',
  menuNotificationOptionClicked: 'Menu Notification Option Clicked',
  MY_BOOKINGS: 'My Bookings',
  MY_INVOICES: 'My Invoices',
  MY_NOTIFICATIONS: 'My Notifications',
  MY_PAYMENT_METHODS: 'My Payment Methods',
  OPEN_PREFERRED_LOCATIONS: 'Open preferred locations',
  PROMOTIONS: {
    title: 'Promotions',
    codeAdded: 'Promo Code Added',
    addCode: 'Promotions - Add Code',
  },
  REFERRALS: 'Referrals',
  REFERRALS_SHARED: 'Referral Code Shared',
  REGISTER_CLICKED: 'Register Clicked',
  RESERVATION_SUMMARY: 'Reservation Summary',
  SET_EMAIL_AND_PASSWORD: 'Email Password Added',
  SET_PHONE_NUMBER: 'Phone Number Added',
  TERMS_ACCEPTED: 'Terms Conditions Accepted',
  UPDATE_PREFERRED_LOCATIONS: 'Update preferred locations',
  USER_SUBSCRIBED: 'User Subscribed',
  VEHICLE_DETAILS_VIEWED: 'Vehicle Details Viewed',
});
