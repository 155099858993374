import get from 'lodash/get';
const POPUP_SIZE = {
  width: 800,
  height: 600,
};

/**
 * Creates a centered popup and focuses it's window
 * @param {string} url The url to load in the popup
 * @param {string} title The title of the popup
 * @param {number} width The width of the popup
 * @param {number} height The height of the popup
 */
export const openCenteredPopup = url => {
  // Fixes dual-screen position                         Most browsers      Firefox
  const dualScreenLeft = window.screenLeft || get(window, 'screen.left');
  const dualScreenTop = window.screenTop || get(window, 'screen.top');

  const innerWidth = window.innerWidth || get(document, 'documentElement.clientWidth', get(window, 'screen.width'));
  const innerHeight = window.innerHeight || get(document, 'documentElement.clientHeight', get(window, 'screen.height'));

  const left = ((innerWidth / 2) - (POPUP_SIZE.width / 2)) + dualScreenLeft;
  const top = ((innerHeight / 2) - (POPUP_SIZE.height / 2)) + dualScreenTop;
  const newWindow = window.open(url, '', `scrollbars=yes, width=${POPUP_SIZE.width}, height=${POPUP_SIZE.height}, top=${top}, left=${left}`);

  // Puts focus on the newWindow
  if (window.focus) {
    newWindow.focus();
  }
};
